/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { images } from '@assets';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PrmBarChart } from './PrmBarChart';
import { GroupBarChart } from './GroupBarChart';
import { EsgVerticalChart } from './EsgVerticalChart';
import { DataLabPieChart } from './DataLabPieChart';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import { getEsgCharts, getProfiles } from '../../common/crud';
import PdfDownloader from '../../common/PdfDownloader';
import PdfTitleModal from './PdfTitleModal';

export default React.memo(function EsgPrm(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const labels = [
    {
      label: '슬라이드 배너',
      color: 'first',
      category: 'SLIDE',
      rgb: '#24427a',
    },
    { label: '바 배너', color: 'second', category: 'BAR', rgb: '#f99b2e' },
    { label: '박스 배너', color: 'third', category: 'BOX', rgb: '#5890c7' },
    { label: '통 배너', color: 'fourth', category: 'FULL', rgb: '#624aef' },
  ];
  const barlables = [
    {
      label: 'CPV',
      color: 'first',
      rgb: 'rgb(238, 83, 124)',
      rgba: 'rgba(238,83,124,0.5)',
    },
    {
      label: 'CPC',
      color: 'second',
      rgb: 'rgb(235, 146, 123)',
      rgba: 'rgba(235, 146, 123,0.5)',
    },
  ];

  const schlabels = [
    { label: 'ESG', color: 'first', category: 'ESG', rgb: '#00974e' },
    { label: '소상공인', color: 'second', category: 'STORE', rgb: '#ff8541' },
    { label: '지자체', color: 'third', category: 'LOCAL-GOV', rgb: '#305399' },
  ];

  const defaultBarOption = {
    categoryPercentage: 1.0,
    barPercentage: 0.5,
  };

  const history = useHistory();

  const orderBy = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const defaultPieDataLabels = {
    labels: {
      name: {
        align: 'top',
        padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            const total = ctx.dataset.data.reduce((sum, item) => sum + item, 0);
            return Utils.calculatePercentage(value, total, 1);
          }
          return '';
        },
      },
      value: {
        align: 'center',
        font: { size: 10, weight: '400' },
        formatter(value, ctx) {
          if (value !== 0) {
            return `${Utils.changeNumberComma(value)}건`;
          }
          return '';
        },
        padding: 4,
      },
    },
  };

  const defaultGroupBarDataLabels = {
    labels: {
      name: {
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          const total = ctx.dataset.data.reduce((sum, item) => sum + item, 0);
          if (value !== 0) {
            return `${value}%`;
          }
          return '';
        },
      },
      // value: {
      //   align: 'center',
      //   font: { size: 10, weight: '400' },
      //   formatter(value, ctx) {
      //     return `${Utils.changeNumberComma(value)}건`;
      //   },
      //   padding: 4,
      // },
    },
  };

  const defaultBarDataLabels = {
    labels: {
      name: {
        align: 'top',
        padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            return `${Utils.changeNumberComma(value)}건`;
          }
          return '';
        },
      },
    },
  };

  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const startDate =
    (history.location.state && history.location.state.startDate) ||
    new Date().setDate(new Date().getDate() - 30);
  const endDate =
    (history.location.state && history.location.state.endDate) || new Date();
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [searchProfile, setSearchProfile] = useState({});
  const [profileList, setProfileList] = useState([]);

  const [areaBarChartData, setAreaBarChartData] = useState();
  const [areaPieChartData, setAreaPieChartData] = useState();
  const [typeBarChartData, setTypeBarChartData] = useState();
  const [typePieChartData, setTypePieChartData] = useState();

  const areaBarChartRef = useRef();
  const areaPieChartRef = useRef();
  const typeBarChartRef = useRef();
  const typePieChartRef = useRef();

  const [esgTableList, setEsgTableList] = useState([]);

  const pdfRef = useRef();

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getEsgChartData = async () => {
    try {
      const params = {
        profileId,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data } = await getEsgCharts(params);
      if (data.code === 200) {
        const { byArea, byType } = data.data;
        const tempAreaBarChartObj = {};
        const tempAreaPipChartObj = {};
        const tempTypeBarChartObj = {};
        const tempTypePipChartObj = {};
        const chartAreaLabels = [];
        const chartTypeLabels = [];

        byArea.forEach(item => {
          if (!tempAreaBarChartObj[item.legend]) {
            tempAreaBarChartObj[item.legend] = [];
          }

          if (!tempAreaPipChartObj[item.category]) {
            tempAreaPipChartObj[item.category] = [];
          }

          tempAreaBarChartObj[item.legend].push(item);
          tempAreaPipChartObj[item.category].push(item);

          if (!chartAreaLabels.find(v => v.category === item.category)) {
            chartAreaLabels.push(
              labels.find(lb => lb.category === item.category),
            );
          }
        });

        chartAreaLabels.sort((a, b) => {
          return orderBy(a.category, b.category);
        });

        const tempAreaBarChartData = {
          labels: chartAreaLabels.map(v => {
            return v.label;
          }),
          datasets: [],
        };
        Object.keys(tempAreaBarChartObj).forEach(key => {
          const tempBarChartDataSet = {
            ...defaultBarOption,
            data: tempAreaBarChartObj[key]
              .sort((a, b) => {
                return orderBy(a.category, b.category);
              })
              .map((v, i) => {
                return v.value;
              }),
            datalabels: defaultBarDataLabels,
            backgroundColor: barlables.find(barlable => barlable.label === key)
              .rgb,
          };
          tempAreaBarChartData.datasets = [
            ...tempAreaBarChartData.datasets,
            tempBarChartDataSet,
          ];
        });

        const tempAreaPieChartData = {
          labels: chartAreaLabels.map(v => {
            return v.label;
          }),
          datasets: [
            {
              data: Object.keys(tempAreaPipChartObj)
                .sort()
                .map(key =>
                  tempAreaPipChartObj[key].reduce(
                    (sum, item) => sum + item.value,
                    0,
                  ),
                ),
              datalabels: defaultPieDataLabels,
              backgroundColor: labels
                .sort((a, b) => {
                  return orderBy(a.category, b.category);
                })
                .map(label => {
                  return label.rgb;
                }),
              hoverOffset: 4,
            },
          ],
        };

        byType.forEach(item => {
          if (!tempTypeBarChartObj[item.legend]) {
            tempTypeBarChartObj[item.legend] = [];
          }

          if (!tempTypePipChartObj[item.category]) {
            tempTypePipChartObj[item.category] = [];
          }

          tempTypeBarChartObj[item.legend].push(item);
          tempTypePipChartObj[item.category].push(item);

          if (!chartTypeLabels.find(v => v.category === item.category)) {
            chartTypeLabels.push(
              schlabels.find(lb => lb.category === item.category),
            );
          }
        });

        chartTypeLabels.sort((a, b) => {
          return orderBy(a.category, b.category);
        });
        const tempEsgTableList = chartTypeLabels.map(v => {
          return {
            ...v,
            cpc: byType.find(
              bt => bt.legend === 'CPC' && bt.category === v.category,
            ),
            cpv: byType.find(
              bt => bt.legend === 'CPV' && bt.category === v.category,
            ),
          };
        });

        setEsgTableList(tempEsgTableList);

        const tempTypeBarChartData = {
          labels: chartTypeLabels.map(v => {
            return v.label;
          }),
          datasets: [],
        };
        Object.keys(tempTypeBarChartObj).forEach(key => {
          const tempTypeBarChartDataSet = {
            ...defaultBarOption,
            data: tempTypeBarChartObj[key]
              .sort((a, b) => {
                return orderBy(a.category, b.category);
              })
              .map((v, i) => {
                return Utils.calculatePercentageInt(v.rateResult, 1, 0);
              }),
            backgroundColor: barlables.find(barlable => barlable.label === key)
              .rgb,
            datalabels: defaultGroupBarDataLabels,
            barPercentage: 0.3,
            stack: `Stack_type_${key}`,
          };
          tempTypeBarChartData.datasets = [
            ...tempTypeBarChartData.datasets,
            tempTypeBarChartDataSet,
          ];
        });

        Object.keys(tempTypeBarChartObj).forEach(key => {
          const tempTypeBarChartDataSet = {
            ...defaultBarOption,
            data: tempTypeBarChartObj[key]
              .sort((a, b) => {
                return orderBy(a.category, b.category);
              })
              .map((v, i) => {
                return Utils.calculatePercentageInt(v.rateExpect, 1, 0);
              }),
            backgroundColor: barlables.find(barlable => barlable.label === key)
              .rgba,
            barPercentage: 0.6,
            stack: `Stack_type_${key}`,
          };
          tempTypeBarChartData.datasets = [
            ...tempTypeBarChartData.datasets,
            tempTypeBarChartDataSet,
          ];
        });

        const tempTypePieCharData = {
          labels: chartTypeLabels.map(v => {
            return v.label;
          }),
          datasets: [
            {
              data: Object.keys(tempTypePipChartObj)
                .sort()
                .map(key =>
                  tempTypePipChartObj[key].reduce(
                    (sum, item) => sum + item.value,
                    0,
                  ),
                ),
              datalabels: defaultPieDataLabels,
              backgroundColor: schlabels
                .sort((a, b) => {
                  return orderBy(a.category, b.category);
                })
                .map(label => {
                  return label.rgb;
                }),
              hoverOffset: 4,
            },
          ],
        };

        setTypeBarChartData(tempTypeBarChartData);
        setTypePieChartData(tempTypePieCharData);
        setAreaBarChartData(tempAreaBarChartData);
        setAreaPieChartData(tempAreaPieChartData);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  // 페이지 이동
  const movePage = () => {
    const state = {
      pageRefresh: !pageRefresh,
      profileId: searchProfile.value,
      startDate: searchStartDate,
      endDate: searchEndDate,
    };

    props.history.push({
      pathname: RouterPath.esgprm,
      state,
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
  };

  useEffect(() => {
    getProfileList();
  }, []);

  useEffect(() => {
    setSearchState();
    getEsgChartData();
  }, [pageRefresh]);

  const [isLoading, setIsLoading] = useState(false);
  const [pdfModalShow, setPdfModalShow] = useState(false);

  const createSearchPdfElement = pdfTitle => {
    const profileObj = profileList.find(v => v.value === profileId) || allData;
    return (
      <>
        <Form className="search-area">
          <h1>{pdfTitle}</h1>
          <Form.Group className="form-group">
            <Form.Label>사업단위</Form.Label>
            <Form.Label>{profileObj.label}</Form.Label>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>조회일</Form.Label>
            <div className="datepicker-wrap mw-280">
              <Moment date={startDate} format="YYYY.MM.DD" interval={0} />
              <span>-</span>
              <Moment date={endDate} format="YYYY.MM.DD" interval={0} />
            </div>
          </Form.Group>
        </Form>
        <article className="mt-4 prmtable">
          <h5>홍보 통계 전체 건수</h5>
          <Table className="table-hover text-start mt-3">
            <colgroup>
              <col width={80} />
              <col width={140} />
              <col width={140} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>CPC</th>
                <th>CPV</th>
              </tr>
            </thead>

            <tbody>
              {esgTableList.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{v.label}</td>
                    <td>{Utils.changeNumberComma(v.cpc.value)}</td>
                    <td>{Utils.changeNumberComma(v.cpv.value)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </article>
      </>
    );
  };

  const createPdfImage = async pdfTitle => {
    setIsLoading(true);
    const tempPdfImages = [];
    const originalStyle = pdfRef.current.style.cssText;
    pdfRef.current.style.cssText += 'max-width: 1920px; box-shadow : unset;';
    pdfRef.current.className = 'not-shadow';
    // mt-4
    await new Promise(resolve => setTimeout(resolve, 100));

    const chartCanvas = await html2canvas(pdfRef.current);

    pdfRef.current.style.cssText = originalStyle;
    pdfRef.current.className = '';
    const chartImageUrl = chartCanvas.toDataURL('image/png');
    const img = document.createElement('img');
    img.src = chartImageUrl;

    const searchDiv = document.createElement('div');
    const searchEl = createSearchPdfElement(pdfTitle);
    const searchContent = ReactDOMServer.renderToString(searchEl);
    searchDiv.innerHTML = searchContent;

    // const chartDiv = document.createElement('div');
    // const chartEl = await createChartElement();
    // const chartContent = ReactDOMServer.renderToString(chartEl);
    // chartDiv.innerHTML = chartContent;

    const firstDiv = document.createElement('div');
    firstDiv.appendChild(searchDiv);
    firstDiv.appendChild(img);
    firstDiv.style.position = 'fixed'; // Add this line
    firstDiv.style.top = '-10000px'; // Add this line
    firstDiv.style.maxWidth = '1920px';
    firstDiv.style.minWidth = '1280px';
    firstDiv.style.id = 'datalab-esg';
    document.body.appendChild(firstDiv);

    const addPdfCanvas = await html2canvas(firstDiv);
    const addPdfImage = addPdfCanvas.toDataURL('image/png');

    const addPdfObj = {
      canvas: addPdfCanvas,
      image: addPdfImage,
    };

    tempPdfImages.push(addPdfObj);
    document.body.removeChild(firstDiv);
    return tempPdfImages;
  };

  const createChartElement = async () => {
    let areaBarChartUrl = '';
    let areaPieChartUrl = '';
    let typeBarChartUrl = '';
    let typePieChartUrl = '';

    const urls = [];

    if (areaBarChartRef && areaBarChartRef.current) {
      const chartCanvas = areaBarChartRef.current.canvas;
      areaBarChartUrl = chartCanvas.toDataURL('image/png');
      urls.push(areaBarChartUrl);
    }
    if (areaPieChartRef && areaPieChartRef.current) {
      const chartCanvas = areaPieChartRef.current.canvas;
      areaPieChartUrl = chartCanvas.toDataURL('image/png');
      urls.push(areaPieChartUrl);
    }
    if (typeBarChartRef && typeBarChartRef.current) {
      const chartCanvas = typeBarChartRef.current.canvas;
      typeBarChartUrl = chartCanvas.toDataURL('image/png');
      urls.push(typeBarChartUrl);
    }
    if (typePieChartRef && typePieChartRef.current) {
      const chartCanvas = typePieChartRef.current.canvas;
      typePieChartUrl = chartCanvas.toDataURL('image/png');
      urls.push(typePieChartUrl);
    }

    const loadImages = urls.map(url => {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
          resolve(this.height);
        };
        img.src = url;
      });
    });

    const heights = await Promise.all(loadImages);
    const maxHeight = Math.max(...heights);

    return (
      <div id="datalab-esg">
        <div className="grid-section">
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>홍보 영역별 노출수 집계 현황</h5>
                <div className="title-labels">
                  {barlables.map((option, i) => (
                    <div className="oplabel-flex" key={`barlable_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="bar-height-pdf"
                style={{ height: maxHeight, objectFit: 'contain' }}
              >
                <img src={areaBarChartUrl} />
              </div>
            </div>
          </article>
          <article className="mt-4">
            <div className="pie-section Responsive-pie">
              <div className="flex-title">
                <h5>홍보 영역별 노출수 비율</h5>
                <div className="title-labels">
                  {labels.map((option, i) => (
                    <div className="oplabel-flex" key={`label_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="pie-height-pdf"
                style={{ height: maxHeight, objectFit: 'contain' }}
              >
                <img src={areaPieChartUrl} />
              </div>
            </div>
          </article>
        </div>
        <div className="grid-section">
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>홍보 노출 비율별 진척 상황</h5>
                <div className="title-labels">
                  {barlables.map(option => (
                    <div className="oplabel-flex" key={`bar_${option.color}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="bar-height-pdf"
                style={{ height: maxHeight, objectFit: 'contain' }}
              >
                <img src={typeBarChartUrl} />
              </div>
            </div>
          </article>
          <article className="mt-4 schdule">
            <div className="pie-section">
              <div className="flex-title">
                <h5>스케줄링 홍보 효과</h5>
                <div className="title-labels">
                  {schlabels.map((option, i) => (
                    <div className="oplabel-flex" key={`schlabel_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="pie-height-pdf"
                style={{ height: maxHeight, objectFit: 'contain' }}
              >
                <img src={typePieChartUrl} />
              </div>
            </div>
          </article>
        </div>
      </div>
    );
  };

  return (
    <main id="datalab-esg">
      {isLoading && <Loading />}
      <Header title="홍보 통계" />
      <Container className="contents container-128">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>조회일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  maxDate={new Date()}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
          </Form>
          <div className="btn-center">
            <Button
              onClick={() => {
                movePage();
              }}
            >
              검색
            </Button>
          </div>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              setPdfModalShow(true);
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>
        <article className="mt-4 prmtable">
          <h5>홍보 통계 전체 건수</h5>
          <Table className="table-hover text-start mt-3">
            <colgroup>
              <col width={80} />
              <col width={140} />
              <col width={140} />
            </colgroup>
            <thead>
              <tr>
                <th />
                <th>CPC</th>
                <th>CPV</th>
              </tr>
            </thead>

            <tbody>
              {esgTableList.map((v, i) => {
                return (
                  <tr key={i}>
                    <td>{v.label}</td>
                    <td>{Utils.changeNumberComma(v.cpc.value)}</td>
                    <td>{Utils.changeNumberComma(v.cpv.value)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </article>

        <div ref={pdfRef}>
          <div className="grid-section">
            <article className="mt-4 chart-section">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>홍보 영역별 노출수 집계 현황</h5>
                  <div className="title-labels">
                    {barlables.map((option, i) => (
                      <div className="oplabel-flex" key={`barlable_${i}`}>
                        <span className={`option-boll ${option.color}`} />
                        <Form.Label>{option.label}</Form.Label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bar-height">
                  {areaBarChartData && (
                    <PrmBarChart
                      ref={areaBarChartRef}
                      chartData={areaBarChartData}
                      isDatalabels
                    />
                  )}
                </div>
              </div>
            </article>
            <article className="mt-4 chart-section">
              <div className="pie-section Responsive-pie">
                <div className="flex-title">
                  <h5>홍보 영역별 노출수 비율</h5>
                  <div className="title-labels">
                    {labels.map((option, i) => (
                      <div className="oplabel-flex" key={`label_${i}`}>
                        <span className={`option-boll ${option.color}`} />
                        <Form.Label>{option.label}</Form.Label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pie-height">
                  {areaPieChartData && (
                    <DataLabPieChart
                      ref={areaPieChartRef}
                      style={{ flex: 1, width: '100%' }}
                      chartData={areaPieChartData}
                    />
                  )}
                </div>
              </div>
            </article>
          </div>
          <div className="grid-section">
            <article className="mt-4 chart-section">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>홍보 노출 비율별 진척 상황</h5>
                  <div className="title-labels">
                    {barlables.map(option => (
                      <div className="oplabel-flex" key={`bar_${option.color}`}>
                        <span className={`option-boll ${option.color}`} />
                        <Form.Label>{option.label}</Form.Label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bar-height">
                  {typeBarChartData && (
                    <GroupBarChart
                      ref={typeBarChartRef}
                      chartData={typeBarChartData}
                    />
                  )}
                </div>
              </div>
            </article>
            <article className="mt-4 schdule chart-section">
              <div className="pie-section">
                <div className="flex-title">
                  <h5>스케줄링 홍보 효과</h5>
                  <div className="title-labels">
                    {schlabels.map((option, i) => (
                      <div className="oplabel-flex" key={`schlabel_${i}`}>
                        <span className={`option-boll ${option.color}`} />
                        <Form.Label>{option.label}</Form.Label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pie-height">
                  {typePieChartData && (
                    <DataLabPieChart
                      ref={typePieChartRef}
                      style={{ flex: 1, width: '100%' }}
                      chartData={typePieChartData}
                    />
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
      </Container>
      {pdfModalShow && (
        <PdfTitleModal
          show={pdfModalShow}
          handleClose={() => {
            setPdfModalShow(false);
          }}
          onDownload={pdfTitle => {
            createPdfImage(pdfTitle)
              .then(e => {
                return PdfDownloader(e);
              })
              .catch(e => {
                CustomSwal.fire({
                  text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                  confirmButtonText: '확인',
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
    </main>
  );
});
function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}
// const esgTableList = [
//   {
//     prm: 'ESG',
//     cpv: '10,000',
//     cpc: '10,000',
//   },
//   {
//     prm: '소상공인',
//     cpv: '10,000',
//     cpc: '10,000',
//   },
//   {
//     prm: '지자체',
//     cpv: '10,000',
//     cpc: '10,000',
//   },
// ];
